@import url(./fonts/kanoma/kanoma-fonts.css);

.fontKanoma {
    font-family: "Kanoma", "Source Sans Pro", Helvetica, sans-serif;;
}

.kano-title {
    font-family: "Kanoma", "Source Sans Pro", Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
}

.kano-logo-shape-1:hover, .kano-logo-shape-4:hover {
    fill: #FFED9E;
    transition:all 0.1s;
}

.kano-logo-shape-2:hover {
    fill:#8E85AA;
    transition:all 0.1s;
}

.kano-logo-shape-3:hover {
    fill:#6D6193;
    transition:all 0.1s;
}

.logo-anim-1 {
    animation: pulseYellow 2s;
    animation-delay: 0s;
}
.logo-anim-2 {
    animation: pulseLightPurple 2s;
    animation-delay: 0.2s;
}
.logo-anim-3 {
    animation: pulsePurple 2s;
    animation-delay: 0.3s;
}
.logo-anim-4 {
    animation: pulseYellow 2s;
    animation-delay: 0.4s;
}

@keyframes pulseYellow {
    0% {
        fill: #FFED9E;
    }
    100% {
        fill: #FFD400;
    }
  }

@keyframes pulseLightPurple {
    0% {
        fill: #8E85AA;
    }
    100% {
        fill: #8070B3;
    }
}

@keyframes pulsePurple {
    0% {
        fill: #6D6193;
    }
    100% {
        fill: #6552A3;
    }
}

