@font-face {
    font-family: 'Kanoma';
    src: url('./Comfortaa-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kanoma';
    src: url('./Comfortaa-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: bold;
}
