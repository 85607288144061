footer {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    width: 60%;
    color: #363636;
    font-size: 0.8em;
    font-family: Nunito Sans, sans-serif;
    height: 50px;
    flex-shrink: 0;
}