

/* couleur kanoma : #43338e #f6d330 */
body {
    margin: 0;
    /*height: 100vh;*/
    width: 100%;

    font-family: "Nunito Sans", sans-serif;
}

label{
    text-transform:capitalize;
}

